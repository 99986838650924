<template>
    <div class="history_box">
        <div class="title flex_center_between_box">
            <div class="title_txt flex_center_start_box">
                <img src="../../assets/img/detail/title_ic15.png" />
                Country History
            </div>
            <div class="c_d_buttom flex_box">
                <button class="btn_blue flex_center" @click="ResetHistory">
                    <img src="../../assets/img/detail/btn_ic3.png" />
                    Reset
                </button>
                <button class="btn_blue flex_center" @click="searchHistory">
                    <img src="../../assets/img/detail/btn_ic5.png" />
                    Search
                </button>
            </div>
        </div>
        <div class="right_cont_box">
            <div class="his_sel_box flex_center_between_box" style="flex-wrap: wrap; justify-content: center;">
                <div class="sel_box">
                    <el-select v-model="CategoryVal" placeholder="Category" @change="firstColumnChangeAction($event)">
                        <el-option v-for="item in CategoryOptions" :key="item.id" :label="item.text" :value="item.val"></el-option>
                    </el-select>
                </div>
                <div class="sel_box">
                    <el-select v-model="IndicatorsVal" placeholder="Indicators" no-data-text="No Data">
                        <el-option v-for="item in IndicatorsOptions" :key="item.id" :label="item.text" :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="sel_box">
                    <el-select v-model="CountryVal" placeholder="Country" multiple collapse-tags filterable no-match-text="No data">
                        <el-option v-for="item in CountryOptions" :key="item.code" :label="item.nameEn" :value="item.countryCode"></el-option>
                    </el-select>
                </div>
                <div class="sel_box sel_boxtwo">
                    <el-select v-model="YearVal1" placeholder="Start year" style="width: 140px" collapse-tags clearable>
                        <el-option :label="item" :value="item" v-for="(item, index) in yearOptions" :key="index"></el-option>
                    </el-select>
                    <span style="margin-right:5px ;">～</span>
                    <el-select v-model="YearVal2" placeholder="End year" style="width: 140px" collapse-tags clearable>
                        <el-option :label="item" :value="item" v-for="(item, index) in yearOptions" :key="index"></el-option>
                    </el-select>
                </div>
            </div>

            <div ref="historyEchart" id="countriesSupplier" v-show="showEchart" style="width: 100%; height: 510px"></div>
        </div>
    </div>
</template>
<script>
import { searchCountryInfoChart, searchCountryInfo } from '@/api/companyApi.js';
import { category } from '@/utils/category';
import crypto from '@/utils/crypto';
import { mixins1 } from '@/mixins/index';

export default {
    mixins: [mixins1],
    data() {
        return {
            CategoryVal: '',
            CategoryOptions: category, //导入详情目录分类
            IndicatorsVal: '',
            IndicatorsOptions: [],
            country: [],
            CountryVal: [],
            CountryOptions: [],
            servieslist: [],
            YearVal: [],
            yearOptions: [],
            yearOptionstwo: [],
            echartYearVal: [],
            showEchart: false,
            echartsBox: '', //echart容器
            selectedYear: '',
            YearVal2: '',
            YearVal1: '',
        };
    },
    computed: {},
    watch: {},
    created() {
        this.getCountry('');
        //types: CE CP CB CR CT 首字母缩写枚举
        //countryCodes: US CN
        //years: String 类型
        this.getData();
    },
    mounted() {
        // this.setEchart()
        this.getYears();
    },
    methods: {
        getCountry(val) {
            this.$store.state.countryImgList = JSON.parse(localStorage.getItem('countryList'));
            this.CountryOptions = this.$store.state.countryImgList[0].countryList;
            let obj = {};
            obj = this.CountryOptions.find(item => {
                //companyListAdd就是上面遍历的数组的数据源
                return item.countryCode === val; //筛选出匹配的数据
            });
            return obj ? obj.nameEn : '';
        },
        getData() {
            let arr = [];

            arr = [
                { name: 'Alice', age: 20 },
                { name: 'Bob', age: 30 },
                { name: 'Charlie', age: 40 },
            ];
            const params = {
                types: [this.CategoryVal],
                countryCodes: this.CountryVal,
                years: this.echartYearVal == [] ? this.yearOptionstwo : this.echartYearVal,
            };
            const params1 = {
                types: ['CT'],
                countryCodes: [this.$route.query.companyCountry],
                // years: [''],
            };
            searchCountryInfo(params1).then(res => {
                let res1 = JSON.parse(crypto.decrypt(res));
                this.yearOptions = res1.data.years.reverse();
            });

            searchCountryInfoChart(params).then(result => {
                let data = JSON.parse(crypto.decrypt(result));

                // console.log(`选择年份数据`, data);
                if (data && data.code && data.data) {
                    //  分离国家与对应数据
                    // this.yearOptions=data.data.years
                    let key = Object.keys(data.data.countryChart);
                    let date = [];
                    key.map(item => {
                        // 根据不同国家 不同类型对应不同数据
                        data.data.countryChart[item].map(ite => {
                            if (this.CategoryVal == 'CP') {
                                ite.countryProfileVO.councd = item;
                                date.push(ite.countryProfileVO);
                            }
                            if (this.CategoryVal == 'CE') {
                                ite.countryEconomyVO.councd = item;
                                date.push(ite.countryEconomyVO);
                            }
                            if (this.CategoryVal == 'CB') {
                                ite.countryBusinessVO.councd = item;
                                date.push(ite.countryBusinessVO);
                            }
                            if (this.CategoryVal == 'CT') {
                                ite.countryTradeVO.councd = item;
                                date.push(ite.countryTradeVO);
                            }
                            if (this.CategoryVal == 'CR') {
                                ite.countryRatingsVO.councd = item;
                                date.push(ite.countryRatingsVO);
                            }
                        });
                    });
                    let ecahrtdata = [];
                    // let type = this.IndicatorsVal
                    let biaojidata = [];
                    date.map((item, index) => {
                        // 获取类型
                        // 插入区分数据
                        let country = this.getCountry(item.councd);
                        let numObj = item[this.IndicatorsVal];
                        let num = '';
                        if (item[this.IndicatorsVal] == 'n.a.') {
                            num = null;
                        } else {
                            num = Number(numObj.replace(/[^\d.]/g, ''));
                        }
                        // ecahrtdata.push({ name: country, data: [item.countryYear,num], type: 'line' })
                        // ecahrtdata.push({ name: country, data: num, type: 'line' })
                        biaojidata.push({ name: item.countryYear, xAxis: item.countryYear, yAxis: num });
                    });

                    //全部年份数据
                    searchCountryInfoChart({
                        types: [this.CategoryVal],
                        countryCodes: this.CountryVal,
                        years: this.echartYearVal == [] ? this.yearOptionstwo : this.echartYearVal,
                    }).then(res => {
                        let resall = JSON.parse(crypto.decrypt(res));
                        console.log(`全部年份数据`, resall);
                        let key = Object.keys(resall.data.countryChart);
                        let date = [];
                        key.map(item => {
                            // 根据不同国家 不同类型对应不同数据
                            resall.data.countryChart[item].map(ite => {
                                if (this.CategoryVal == 'CP') {
                                    ite.countryProfileVO.councd = item;
                                    date.push(ite.countryProfileVO);
                                }
                                if (this.CategoryVal == 'CE') {
                                    ite.countryEconomyVO.councd = item;
                                    date.push(ite.countryEconomyVO);
                                }
                                if (this.CategoryVal == 'CB') {
                                    ite.countryBusinessVO.councd = item;
                                    date.push(ite.countryBusinessVO);
                                }
                                if (this.CategoryVal == 'CT') {
                                    ite.countryTradeVO.councd = item;
                                    date.push(ite.countryTradeVO);
                                }
                                if (this.CategoryVal == 'CR') {
                                    ite.countryRatingsVO.councd = item;
                                    date.push(ite.countryRatingsVO);
                                }
                            });
                        });
                        let ecahrtdata = [];

                        date.map((item, index) => {
                            // 获取类型

                            // 插入区分数据
                            let country = this.getCountry(item.councd);
                            let numObj = item[this.IndicatorsVal];
                            let num = '';
                            if (item[this.IndicatorsVal] == 'n.a.') {
                                num = null;
                            } else {
                                num = Number(numObj.replace(/[^\d.]/g, ''));
                            }
                            if (num == 0) {
                                num = '';
                            }
                            // ecahrtdata.push({ name: country, data: [item.countryYear,num], type: 'line' })
                            ecahrtdata.push({ name: country, data: num, type: 'line' });
                            // biaojidata.push({name:item.countryYear,xAxis: item.countryYear, yAxis: num})
                        });
                        let dataInfo = {};
                        let that = this;

                        ecahrtdata.forEach((item, index) => {
                            let { name, type } = item;
                            if (!dataInfo[name]) {
                                if (this.YearVal.length > 0) {
                                    dataInfo[name] = {
                                        name,
                                        type,
                                        data: [],
                                        // markPoint: {
                                        //     symbol: 'diamond',
                                        //     symbolSize: 10,
                                        //     itemStyle: {
                                        //         borderWidth: 1,
                                        //         borderColor: 'red',
                                        //         color: 'red',
                                        //     },

                                        //     data: biaojidata,
                                        // },
                                    };
                                } else {
                                    dataInfo[name] = {
                                        name,
                                        type,
                                        data: [],
                                        // markPoint: {
                                        //     symbol: 'diamond',
                                        //     symbolSize: 10,
                                        //     itemStyle: {
                                        //         borderWidth: 1,
                                        //         borderColor: 'red',
                                        //         color: 'red',
                                        //     },

                                        //     data: '',
                                        // },
                                    };
                                }
                            }
                            dataInfo[name].data.push(item.data);
                        });

                        that.servieslist = Object.values(dataInfo); // list 转换成功的数据
                        if (this.showEchart) {
                            let myChart = this.$echarts.init(document.getElementById('countriesSupplier'));
                            myChart.dispose();
                            that.setEchart(that.servieslist);
                        }
                    });
                }
            });
        },
        // 一级改动
        firstColumnChangeAction(e) {
            this.CategoryVal = e;
            this.IndicatorsVal = '';
            var list = this.CategoryOptions.filter(item => {
                return item.val == this.CategoryVal;
            });
            this.IndicatorsOptions = list[0].children;
        },

        //搜索历史记录
        searchHistory() {
            this.showEchart = true;
            let list = [];
            this.getqujian();
            if (this.YearVal.length > 0) {
                list = this.yearOptionstwo;
            } else {
                list = this.yearOptions;
            }
            this.echartYearVal = list.sort(function (a, b) {
                return a - b;
            });

            this.getData();
        },

        //获取年 2008-2023
        getqujian() {
            this.YearVal = [this.YearVal1, this.YearVal2];
            const loadYear = Math.max(...this.YearVal);
            const n = loadYear - Math.min(...this.YearVal);

            let list = [];
            for (var i = 0; i <= n; i++) {
                const year = loadYear - i;
                list.push(year);
            }
            this.yearOptionstwo = [...new Set(list)];
        },
        // 获取区间
        getYears() {
            const loadYear = new Date().getFullYear();
            const n = loadYear - 2007;
            for (var i = 0; i < n; i++) {
                const year = loadYear - i;
                this.yearOptions.push(year);
            }
        },
        //重置搜索结果
        ResetHistory() {
            this.showEchart = false;
            this.YearVal = [];
            this.CountryVal = [];
            this.IndicatorsVal = '';
            this.CategoryVal = '';
            this.IndicatorsOptions = [];
        },

        setEchart(servieslist) {
            let years = this.yearOptionstwo.length > 0 ? this.yearOptionstwo : this.yearOptions;
            var that = this;
            this.echartsBox = this.$echarts.init(this.$refs.historyEchart);
            let optionline = {
                tooltip: {
                    trigger: 'axis',
                    loading: false,
                },
                // legend: {
                //     data: servieslist.forEach(item => {
                //         let b = [item.name];
                //         return b;
                //     }),
                //     left: 'center',
                //     bottom: 10,
                // },
                xAxis: {
                    // type: "value",
                    type: 'category',
                    boundaryGap: false,
                    axisTick: {
                        alignWithLabel: true,
                    },
                    // prettier-ignore
                    data: years,
                },

                yAxis: {
                    type: 'value',

                    splitLine: {
                        show: true,
                        // interval:2,
                    },
                    axisLine: {
                        show: true,
                    },
                    axisLabel: {
                        show: true,
                    },
                    axisTick: {
                        show: false,
                    },
                },

                series: servieslist,
                //    :[
                //       {
                //         name: 'aaa',

                //         data: [
                //           ['2021', 4400.04],
                //           ['2020', 4203.04],

                //         ],
                //         type: 'scatter',
                //       },
                //       {
                //   name: 'bbb',
                //   type: 'scatter',
                //   emphasis: {
                //     focus: 'series'
                //   },
                //   // prettier-ignore
                //   data: [ ['2020', 4400.04],

                //         ],

                // },
                //     ],
            };
            this.echartsBox.setOption(optionline);
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}

.country_box {
    margin-top: 60px;
}

.country_box .title {
    margin-bottom: 0;
}

.history_box {
    margin-top: 22px;
}

.title {
    margin-bottom: 20px;
}

.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}

.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.c_d_buttom {
    justify-content: flex-end;
    margin-top: 20px;
    margin-bottom: 0;
}

.his_sel_box .sel_box /deep/ .el-input__inner {
    border-color: #8497ab;
    border-radius: 8px;
}
.his_sel_box .sel_box /deep/ .el-input__inner {
    width: 276px;
}
.his_sel_box .sel_box /deep/ .el-input__inner {
    width: 276px;
}
.his_sel_box .sel_boxtwo /deep/ .el-input__inner {
    width: 135px !important;
}
.his_sel_box .sel_box /deep/ .el-select .el-icon-arrow-up:before {
    color: #8497ab;
    font-weight: 500;
}

.btn_blue {
    width: 150px;
    height: 40px;
    background: #1290c9;
    border-radius: 12px;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
}

.btn_blue img {
    width: 18px;
    height: 18px;
    margin-right: 6px;
}

.c_d_buttom button + button {
    margin-left: 10px;
}
@media (max-width: 821px) {

.sel_box{
    margin-top: 10px !important;
}
.btn_blue{
    width: 120px !important;
}
}
</style>
