export const category=[
    {
        "id": "1-1",
        "text": "Country Profile",
        "val":'CP',
        "children": [
                {
                    "id": "landsize",
                    "text": "Land Size",
                },
                {
                    "id": "population",
                    "text": "Population",
                },
            ]
        },
        {
        "id": "1-2",
        "text": "Economy",
        "val":'CE',
        "children": [
                {
                    "id": "gdpPpp",
                    "text": "GDP (PPP)",
                },
                {
                    "id": "gdp",
                    "text": "GDP / Capita",
                },
                {
                    "id": "fdiInflows",
                    "text": "FDI Inflows",
                },
                {
                    "id": "fdiOutflows",
                    "text": "FDI Outflows",
                },
                {
                    "id": "governmentDebt",
                    "text": "Government Debt",
                },
                {
                    "id": "governmentDebtToGdp",
                    "text": "Government Debt to GDP",
                },
                {
                    "id": "centralBankBalance",
                    "text": "Central Bank Balance Sheet",
                },
                {
                    "id": "moneySupplyM1",
                    "text": "Money Supply M1",
                },
                {
                    "id": "moneySupplyM2",
                    "text": "Money Supply M2",
                },
                {
                    "id": "interestRate",
                    "text": "Interest Rate",
                },
                {
                    "id": "inflationRate",
                    "text": "Inflation Rate",
                },
                {
                    "id": "consumerPriceIndex",
                    "text": "Consumer Price Index (CPI)",
                },
                {
                    "id": "consumerConfidence",
                    "text": "Consumer Confidence",
                },
            ]
        },
        {
        "id": "1-3",
        "text": "Trade",
        "val":'CT',
        "children": [
                {
                    "id": "totalTrade",
                    "text": "Total Trade",
                },
                {
                    "id": "totalExport",
                    "text": "Total Export",
                },
                {
                    "id": "totalImport",
                    "text": "Total Import",
                },
                {
                    "id": "tradeNetBalance",
                    "text": "Trade Net Balance",
                },
                {
                    "id": "tradeGoodsExports",
                    "text": "Trade Goods Exports",
                },
                {
                    "id": "tradeGoodsImports",
                    "text": "Trade Goods Imports",
                },
                {
                    "id": "tradeServicesExports",
                    "text": "Trade Services Exports",
                },
                {
                    "id": "tradeServicesImports",
                    "text": "Trade Services Imports",
                },
            ]
        },
        {
        "id": "1-4",
        "text": "Business",
        "val":'CB',
        
        "children": [
                {
                    "id": "employmentRate",
                    "text": "Employment",
                },
                {
                    "id": "unemploymentRate",
                    "text": "Unemployment",
                },
            ]
        },
        {
        "id": "1-5",
        "text": "Ratings",
        "val":'CR',
        "children": [
                {
                    "id": "businessClimate",
                    "text": "Business Climate Assessment",
                },
            ]
        },
]
export default category